import * as React from "react";
import { ClientCard } from "../App/ClientCard";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from "@material-ui/core/Button";
import { StoreContext } from "../../store/StoreProvider";
import AppConfig from "../../constants/AppConfig";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { MultiInput } from "../MultiInput";
import {
  getWarranty,
  getWarrantyById,
  WarrantyRequestChat,
} from "../../store/sagas/Warranty";
import Loading from "../Loading";
import { types } from "../../store/Warranty";
import { getI18N } from '../../i18n'

const {
  functions: { setColorWarrantyState, setLabelWarrantyState, setLabelWarrantyStateEn },
  colors: { primary, secondary, success, danger },
} = AppConfig;

interface WarrantyDetailProps { }

function CommentArea() {
  const [store, dispatch] = React.useContext(StoreContext);
  const [comment, setComment] = React.useState<string>("");
  const [loading, setLoad] = React.useState<boolean>(false);

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  const send = () => {
    setLoad(true);
    let obj = {
      BaseEntry: store?.warrantyDetail?.DocEntry,
      Mensaje: comment,
      Tipo: store?.user?.tipo,
      UsrReg: store?.user?.user_login,
    };
    WarrantyRequestChat(obj).then(() => {
      let newComment = {
        BaseEntry: store?.warrantyDetail?.DocEntry,
        Docentry: store?.warrantyDetail?.DocEntry,
        FecReg: moment().format(),
        FecUpd: moment().format(),
        FlgEnviado: false,
        FlgLeido: false,
        Id: store?.warrantyDetail?.Conversacion.length + 1,
        Mensaje: comment,
        Tipo: store?.user?.tipo,
        UsrLee: "",
        UsrReg: store?.user?.user_login,
      };
      dispatch({ type: types.addComment, payload: newComment });
      setComment("");
      setLoad(false);
    });
  };

  return (
    <div className="comment-area">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField
              fullWidth
              className="input-battery"
              label={i18n.COMMENT}
              sx={{ backgroundColor: "white" }}
              variant="outlined"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
            />
          </Grid>
          <Grid item xs={2} className="button-send-container">
            <IconButton onClick={send}>
              <Loading loading={loading}>
                <SendIcon sx={{ color: `${primary}!important` }} />
              </Loading>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export const WarrantyDetail = (props: WarrantyDetailProps) => {
  const [store, dispatch] = React.useContext(StoreContext);
  const [local_baterry, setBaterry] = React.useState<any>({});

  const { Country } = store?.user;
  const currentLocale: string = Country || "PE";
  const i18n = getI18N({ currentLocale })

  React.useEffect(() => {
    let obj = {
      idTarjeta: -1,
      qrCode: store?.warrantyDetail?.IdMiniCod,
      placa: "",
      ruc: "",
      cardCode: "",
      // origenBat: store?.user?.Country === "PE" ? "NAC" : store?.user?.Country,
      origenBat: store?.user?.Country,
    };

    if (!store?.userAppCorp) {
      getWarranty(obj)
        .then((dataWarranty) => {
          if (!!dataWarranty.length) {
            setBaterry(dataWarranty[0]);
          }
        })
        .catch((error) => { });
    }

  }, []);

  const CommentBubble = (obj: any) => {
    return (
      <div className="comment-bubble">
        <span className="date">
          {moment(obj?.FecReg).format("DD/MM/yyyy HH:mm:ss")}
        </span>
        <p className="comment-text">{obj?.Mensaje}</p>
      </div>
    );
  };

  const datosMantenimiento = store?.userAppCorp ? store?.warrantyDetail?.DatosMantenimiento : store?.warrantyDetail?.Datos;

  return (
    <div className="new-battery-container new-battery-container-detail">
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ padding: "1rem", background: "#FFFFFF", borderRadius: "15px" }}>
          <Box sx={{ flexGrow: 1 }} >
            <Grid container spacing={1} >
              <Grid item xs={8}>
                <span style={{ fontSize: 15, fontWeight: "bold" }}>
                  {store?.warrantyDetail?.CardName ||
                    store?.selected_client?.RzSocial}
                </span>
              </Grid>
              <Grid item xs={4} className="state-container">
                <span
                  style={{
                    fontSize: "0.7em",
                    fontWeight: "bold",
                    color: setColorWarrantyState(
                      Number(store?.warrantyDetail?.IdEstado)
                    ),
                  }}
                >
                  { store?.user?.Country === "TT" ? setLabelWarrantyStateEn(Number(store?.warrantyDetail?.IdEstado)) : setLabelWarrantyState(Number(store?.warrantyDetail?.IdEstado))}
                </span>
              </Grid>
            </Grid>
          </Box>
          <div className="code-battery-container">
            {(store?.warrantyDetail?.OrigenBat !== "IMP" && ((store?.userAppCorp && store?.warrantyDetail?.MiniCodigo) || !store?.userAppCorp)) && (
              <TextField
                fullWidth
                className="input-battery"
                label={i18n.BATTERY_CODE}
                variant="outlined"
                value={store?.warrantyDetail?.IdMiniCod ?? store?.warrantyDetail?.MiniCodigo}
                disabled={true}
              />
            )}
            <Box sx={{ flexGrow: 1 }}>
              {(store?.warrantyDetail?.OrigenBat !== "IMP" && ((store?.userAppCorp && store?.warrantyDetail?.MiniCodigo) || !store?.userAppCorp)) && (
                <Grid container spacing={1} sx={{ mb: 1 }}>
                  <Grid item xs={3}>
                    <div className="battery-detail-container">
                      <span className="title">
                        {(store?.userAppCorp ? store?.warrantyDetail?.DatosBateria?.Voltaje : local_baterry?.Voltaje) || `0.00`}V
                      </span>
                      <span className="subtitle">{i18n.VOLTAGE}</span>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="battery-detail-container">
                      <span className="title">
                        {(store?.userAppCorp ? store?.warrantyDetail?.DatosBateria?.CN : local_baterry?.CapacidadNominal) || 0}Ah
                      </span>
                      <span className="subtitle">CN</span>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="battery-detail-container">
                      <span className="title">
                        {(store?.userAppCorp ? store?.warrantyDetail?.DatosBateria?.CCA : local_baterry?.CorrienteArranqueEnFrio) || 0}A
                      </span>
                      <span className="subtitle">CCA</span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="battery-detail-container">
                      <span className="title">
                        {(store?.userAppCorp ? store?.warrantyDetail?.DatosBateria?.Densidad : local_baterry?.Densidad) || `0.000`}
                      </span>
                      <span className="subtitle">{i18n.DENSITY}</span>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="battery-detail-container" style={{ height: "100%" }}>
                      <CheckCircleIcon color="success" />
                    </div>
                  </Grid>
                </Grid>
              )}
              <TextField
                fullWidth
                className="input-battery"
                label={i18n.MODEL}
                variant="outlined"
                value={store?.warrantyDetail?.Modelo}
                disabled={true}
              />
              {store?.warrantyDetail?.Placa &&
                <TextField
                  fullWidth
                  className="input-battery"
                  label={i18n.PLATE}
                  variant="outlined"
                  value={store?.warrantyDetail?.Placa}
                  disabled={true}
                />
              }

            </Box>
          </div>
          {datosMantenimiento?.map((item: any, i: number) => {
            let placeholder = item?.PlaceHolder ?? item?.Placeholder;
            if (!!item?.FlgObligatorio) placeholder = `${placeholder} (*)`;
            return (
              <MultiInput
                key={i}
                data={item}
                requred={item?.FlgObligatorio}
                placeholder={placeholder}
                className="input-battery"
                parent=""
                keyword={JSON.stringify(item)}
                disabled={true}
              />
            );
          })}
          <div className="commets">
            {!store?.userAppCorp && (
              <CommentArea />
            )}

            <div className="comments-list">
              {store?.userAppCorp && (
                <div className="comment-bubble">
                  <p className="comment-text">{store?.warrantyDetail?.Comentario}</p>
                </div>
              )}

              {store?.warrantyDetail?.Conversacion?.map((item: any, k: number) => (
                <CommentBubble {...item} key={k} />
              ))}
            </div>
          </div>

          {
            (store?.user?.Country === "PE" || store?.user?.Country === "NAC") ?
              (<div style={{ padding: "1rem 2rem 2rem 1rem", textAlign: "justify" }}>
                {i18n.WARRANTY_INFO}
              </div>)
              : <></>
          }
        </Grid>
      </Grid>


    </div>
  );
};
